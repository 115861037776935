// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Copyright 2020 CFRA Research. All Rights Reserved.

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import About from 'pages/About'
import { Admin } from 'pages/Admin'
import Apis from 'pages/Apis'
import Home from 'pages/Home'
import GuideContents from 'pages/GuideContents'
import GuideContentDetailed from 'pages/GuideContentDetailed'
import GuideContentOverview from 'pages/GuideContentOverview'
import GuideErrorMessages from 'pages/GuideErrorMessages'
import GuideStructureAPI from 'pages/GuideStructureAPI'
import GuideSupport from 'pages/GuideSupport'
import GuideUsageAPI from 'pages/GuideUsageAPI'
import Support from 'pages/Support'
import Webhooks from 'pages/Webhooks'

// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import Feedback from 'components/Feedback'
import Footer from 'components/Footer'
import NavBar from 'components/NavBar'

import { isAdmin, isRegistered, init, login, logout } from 'services/self'
import './index.css'

loadFragments()

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isRegistered()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={props => (
      isAdmin()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

// To shut up a dev warning
const AboutWrap = props => <About {...props} />
const GuideContentsWrap = props => <GuideContents {...props} />
const GuideContentDetailedWrap = props => <GuideContentDetailed {...props} />
const GuideContentOverviewWrap = props => <GuideContentOverview {...props} />
const GuideErrorMessagesWrap = props => <GuideErrorMessages {...props} />
const GuideStructureAPIWrap = props => <GuideStructureAPI {...props} />
const GuideSupportWrap = props => <GuideSupport {...props} />
const GuideUsageAPIWrap = props => <GuideUsageAPI {...props} />
const HomeWrap = props => <Home {...props} />
const SupportWrap = props => <Support {...props} />
const WebhooksWrap = props => <Webhooks {...props} />

class App extends React.Component {
  constructor () {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
  }

  render () {
    return (
      <BrowserRouter>
        <div className='main-fragment'>
          <>
            <NavBar />
            <GlobalModal />
            <Switch>
              <Route exact path='/' component={HomeWrap} />
              <Route
                exact
                path='/index.html'
                component={() => {
                  const { action } = queryString.parse(window.location.search)
                  if (action === 'login') {
                    login()
                  } else if (action === 'logout') {
                    logout()
                  }
                  return <Redirect to='/' />
                }}
              />
              <RegisteredRoute path='/guide-contents' component={GuideContentsWrap} />
              <RegisteredRoute path='/guide-content-detailed' component={GuideContentDetailedWrap} />
              <RegisteredRoute path='/guide-content-overview' component={GuideContentOverviewWrap} />
              <RegisteredRoute path='/guide-error-messages' component={GuideErrorMessagesWrap} />
              <RegisteredRoute path='/guide-structure-api' component={GuideStructureAPIWrap} />
              <RegisteredRoute path='/guide-support' component={GuideSupportWrap} />
              <RegisteredRoute path='/guide-usage-api' component={GuideUsageAPIWrap} />
              <RegisteredRoute path='/webhooks' component={WebhooksWrap} />
              <RegisteredRoute path='/support' component={SupportWrap} />
              <RegisteredRoute path='/about' component={AboutWrap} />
              <AdminRoute path='/admin' component={Admin} />
              <RegisteredRoute exact path='/apis' component={Apis} />
              <RegisteredRoute exact path='/apis/:apiId' component={Apis} />
              <RegisteredRoute path='/apis/:apiId/:stage' component={Apis} />
              <Route path='/login' render={() => { login(); return <Redirect to='/' /> }} />
              <Route path='/logout' render={() => { logout(); return <Redirect to='/' /> }} />
              <Route component={() => <h2>Page not found</h2>} />
            </Switch>
            {feedbackEnabled && <Feedback />}
            <AlertPopup />
          </>
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

// Changes trigger
ReactDOM.render(<App />, document.getElementById('root'))
