import React, { Component } from 'react'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'

import { SideNav } from './'
import { AdminRoute } from './../../'
import PageWithSidebar from 'components/PageWithSidebar'

import RegisteredAccounts from 'pages/Admin/Accounts/RegisteredAccounts'
import AdminAccounts from 'pages/Admin/Accounts/AdminAccounts'
import PendingInvites from 'pages/Admin/Accounts/PendingInvites'
// import PendingRequests from 'pages/Admin/Accounts/PendingRequests'

function RedirectToAccounts () {
  return <Redirect to='/admin/accounts' />
}

export class Admin extends Component {
  render () {
    return (
      <Router>
        <PageWithSidebar sidebarVisible={true} sidebarContent={<SideNav />}>
          <>
            <AdminRoute exact from='/admin' component={RedirectToAccounts} />
            <AdminRoute exact path='/admin/accounts' component={RegisteredAccounts} />
            <AdminRoute exact path='/admin/accounts/admins' component={AdminAccounts} />
            <AdminRoute exact path='/admin/accounts/invites' component={PendingInvites} />
            {/* <AdminRoute path='/admin/apis' component={ApiManagement} /> */}
            {/* <AdminRoute exact path='/admin/accounts/requests' component={PendingRequests} /> */}
          </>
        </PageWithSidebar>
      </Router>
    )
  }
}
