// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Copyright 2020 CFRA Research. All Rights Reserved.

import React from 'react'

import 'font_styles.css'

// mobx
import { observer } from 'mobx-react'

// semantic-ui
import { Segment, Item } from 'semantic-ui-react'

import {isAuthenticated, getCognitoUrl} from "../services/self";

const getOrLoginRedirect = (path) => {
  return isAuthenticated()? path: getCognitoUrl('login')
}

export const HomePage = observer(() => (
  <div style={{ overflow: 'auto', paddingTop: '3em' }}>
    <Segment vertical textAlign='center'
             style={{ border: 'none', margin: '0px !important' }}>
      <Item.Group>
        <Item.Image centered size='small' src='/custom-content/images/cfra-logo.png' />
        <Item.Content>
          <Item.Header as='h1' style={{ color: '#002b5a' }}>Developer Portal</Item.Header>
          <Item.Description style={{ color: '#002b5a' }}>Your Gateway To Our Content</Item.Description>
        </Item.Content>
      </Item.Group>
    </Segment>
    <Segment vertical style={{ display: 'flex', justifyContent: 'center', border: 'none', paddingTop: '3em', margin: '0 !important' }}>
      <Item.Group relaxed='very'>
        <Item style={{paddingBottom: '1em'}}>
          <Item.Image size='tiny' src='/custom-content/icons/compass.png' />
          <Item.Content verticalAlign='middle'>
            <Item.Header className='home-headers'>Get Guidance</Item.Header>
            <Item.Description style={{ color: '#002b5a' }}>
              Review our API documentation via
              our <a href={getOrLoginRedirect('/guide-contents')}>User Guide</a> section
            </Item.Description>
          </Item.Content>
        </Item>
        <Item style={{paddingBottom: '1em'}}>
          <Item.Image size='tiny' src='/custom-content/icons/rocket.png' />
          <Item.Content verticalAlign='middle'>
            <Item.Header className='home-headers'>Experiment</Item.Header>
            <Item.Description style={{ color: '#002b5a' }}>
              Try the <a href={getOrLoginRedirect('/apis')}>CFRA API</a> out for yourself
            </Item.Description>
          </Item.Content>
        </Item>
        <Item style={{paddingBottom: '1em'}}>
          <Item.Image size='tiny' src='/custom-content/icons/research.png' />
          <Item.Content verticalAlign='middle'>
            <Item.Header className='home-headers'>Stay Updated</Item.Header>
            <Item.Description style={{ color: '#002b5a' }}>
              Discover how we use <a href={getOrLoginRedirect('/webhooks')}>Webhooks</a> to ensure you
              always have the latest content
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  </div>
))

export default HomePage
