// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  getCognitoUrl,
  logout
} from 'services/self'

import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'

export const NavBar = observer(
  class NavBar extends React.Component {
    render () {
      const email = store.user && store.user.email
      return <Menu inverted borderless attached style={{ flex: '0 0 auto', background: '#002b5a' }} stackable>
        <MenuLink to='/'>
          <Image src='/custom-content/images/cfra-developers-logo.png' style={{ paddingBottom: '.1em', width: '15em' }} />
        </MenuLink>
        <MenuLink to='/'>Home</MenuLink>
        {isAuthenticated() && <MenuLink to='/guide-contents'>{fragments.GuideContents.title}</MenuLink>}
        {isAuthenticated() && <MenuLink to='/webhooks'>{fragments.Webhooks.title}</MenuLink>}
        {isAuthenticated() && <MenuLink to='/apis'>Endpoints</MenuLink>}
        {isAuthenticated() && <MenuLink to='/support'>{fragments.Support.title}</MenuLink>}
        {isAuthenticated() && <MenuLink to='/about'>{fragments.About.title}</MenuLink>}

        <Menu.Menu position='right'>
          {isAuthenticated() ? <>
            {isAdmin() && <MenuLink to='/admin/apis'>Admin Panel</MenuLink>}
            <MenuLink onClick={logout}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                {email && <span style={{ marginBottom: '.5rem' }}>
                  {email}
                </span>}
                <span>
                  Sign out
                </span>
              </div>
            </MenuLink>
          </> : <>
            <MenuLink to={getCognitoUrl('login')}>Sign In</MenuLink>
          </>}
        </Menu.Menu>
      </Menu>
    }
  }
)

export default NavBar
