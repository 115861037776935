import React from 'react'

import InfoContainerReplacement from 'components/InfoContainerReplacement'

export default class SwaggerLayout extends React.Component {
    render() {
      const {
        errSelectors,
        specSelectors,
        getComponent
      } = this.props
  
      let SvgAssets = getComponent("SvgAssets")
      let VersionPragmaFilter = getComponent("VersionPragmaFilter")
      let Operations = getComponent("operations", true)
      let Models = getComponent("Models", true)
      let Col = getComponent("Col")
      let Errors = getComponent("errors", true)
  
      const ServersContainer = getComponent("ServersContainer", true)
      const SchemesContainer = getComponent("SchemesContainer", true)
      const AuthorizeBtnContainer = getComponent("AuthorizeBtnContainer", true)
  
      let isSwagger2 = specSelectors.isSwagger2()
      let isOAS3 = specSelectors.isOAS3()
  
      const isSpecEmpty = !specSelectors.specStr()
      const loadingStatus = specSelectors.loadingStatus()
  
      let loadingMessage = null
  
      if (loadingStatus === "loading") {
        loadingMessage = <div className="info">
          <div className="loading-container">
            <div className="loading"></div>
          </div>
        </div>
      }
  
      if (loadingStatus === "failed") {
        loadingMessage = <div className="info">
          <div className="loading-container">
            <h4 className="title">Failed to load API definition.</h4>
            <Errors />
          </div>
        </div>
      }
  
      if (loadingStatus === "failedConfig") {
        const lastErr = errSelectors.lastError()
        const lastErrMsg = lastErr ? lastErr.get("message") : ""
        loadingMessage = <div className="info" style={{ maxWidth: "880px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
          <div className="loading-container">
            <h4 className="title">Failed to load remote configuration.</h4>
            <p>{lastErrMsg}</p>
          </div>
        </div>
      }
  
      if (!loadingMessage && isSpecEmpty) {
        loadingMessage = <h4>No API definition provided.</h4>
      }
  
      if (loadingMessage) {
        return <div className="swagger-ui">
          <div className="loading-container">
            {loadingMessage}
          </div>
        </div>
      }
  
      const servers = specSelectors.servers()
      const schemes = specSelectors.schemes()
      const endpoint = (servers && servers.size) ? servers.getIn([0, 'url']) : null
  
      const hasServers = servers && servers.size
      const hasSchemes = schemes && schemes.size
      const hasSecurityDefinitions = !!specSelectors.securityDefinitions()
  
      const info = specSelectors.info()
      const infoContainerDetails = {
        version: specSelectors.version(),
        apiTitle: info.get('title'),
        apiDescription: info.get('description'),
        contactData: info.get('contact'),
        endpoint: endpoint
      }
  
      return (
        <div className='swagger-ui'>
            <SvgAssets />
            <VersionPragmaFilter isSwagger2={isSwagger2} isOAS3={isOAS3} alsoShow={<Errors />}>
              <Errors />
              <div className="main-container">
                <InfoContainerReplacement {...infoContainerDetails} />
                <div className="scheme-container">
                  <Col className="schemes wrapper" mobile={12}>
                    {hasServers ? (<ServersContainer />) : null}
                    {hasSchemes ? (<SchemesContainer />) : null}
                    {hasSecurityDefinitions ? (<AuthorizeBtnContainer />) : null}
                  </Col>
                </div>
                <Operations />
                <Models />
                <div className='swagger-pre-footer-padding'></div>
              </div>
            </VersionPragmaFilter>
        </div>
      )
    }
  }
