import React from 'react'

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className='footer-content'>
          <p>
            Copyright © {new Date().getFullYear()} CFRA. All rights reserved.
            CFRA and STARS are registered trademarks of CFRA.
          </p>
        </div>
      </footer>
    )
  }
}
