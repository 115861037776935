// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

import 'font_styles.css'

import { Header, Image, Container } from 'semantic-ui-react'
import { store } from 'services/state.js'

export class InfoContainerReplacement extends React.Component {
  render() {
    return (
      <Container fluid textAlign='left' className='fixfloat' style={{ padding: '40px 0px' }}>
        <div style={{ display: 'flex', color: '#002b5a' }}>
          <div style={{ flex: '0 0 auto', marginRight: '20px' }}>
            <Image size='small' src={store.api.logo} />
          </div>
          <div>
            <Header as='h1' className='main-container-header'>{this.props.apiTitle}</Header>
            <div style={{ display: 'flex', paddingBottom: '1em' }}>
              <div style={{ marginRight: '20px' }}>
                {store.api.apiStage == null ? <p style={{ fontWeight: 'bold' }}>Version</p> : null}
                {this.props.endpoint ? <p style={{ fontWeight: 'bold' }}>Endpoint</p> : null}
                {this.props.apiDescription ? <p style={{ fontWeight: 'bold' }}>Description</p> : null}
                {this.props.contactData ? <p style={{ fontWeight: 'bold' }}>Contact us</p> : null }
              </div>
              <div>
                {store.api.apiStage == null ? <p>{this.props.version}</p> : null}
                {this.props.endpoint ? <p>{this.props.endpoint}</p> : null}
                {this.props.apiDescription ? <p>{this.props.apiDescription}</p> : null}
                {this.props.contactData ? <a href="mailto:TechAPI@cfraresearch.com">TechAPI@cfraresearch.com</a> : null }
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default InfoContainerReplacement
